"use client";

import ExternalLink from '../ExternalLink';

const Category = ({ name }) => {

  const text = name.split('-').map((word) => word[0].toUpperCase() + word.slice(1)).join(' ');
  return (
    <ExternalLink target='_blank' href={'https://medium.com/tag/' + name}  className='text-[#56baa0] hover:text-[#16f2b3]'>
      <span style={{
        marginRight: '4px',
        marginBottom: '4px',
        padding: '2px 6px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        display: 'inline-flex',
        borderRadius: '4px'
      }}> {text} </span>
    </ExternalLink>


  );
};

export default Category;