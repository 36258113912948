// @flow strict
import BlogCard from "../components/homepage/blog/blog-card";
import * as MediumAPI from '../api/medium/mediumApi';
import { parse } from 'node-html-parser';
import { useEffect, useState } from 'react';

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Example of fetching data using the ProjectsApi
    const fetchBlogs = async () => {
      const data = await MediumAPI.getUserStories();
      setBlogs(data);
    };

    fetchBlogs();
  }, []);

  return (
    <div className="py-8">
      <div className="flex justify-center my-5 lg:py-8">
        <div className="flex  items-center">
          <span className="w-24 h-[2px] bg-[#1a1443]"></span>
          <span className="bg-[#1a1443] w-fit text-white p-2 px-5 text-2xl rounded-md">
            All Blog
          </span>
          <span className="w-24 h-[2px] bg-[#1a1443]"></span>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-5 lg:gap-8 xl:gap-10">
        {
          blogs.length && blogs.map((blog, i) => {
            const desc = parse(blog.content);
            const img = desc.getElementsByTagName('img')[0].getAttribute('src');
            blog.cover_image = img;
            blog.description = '';
            return blog &&
            <BlogCard blog={blog} key={i} />
          })
        }
      </div>
    </div>
  );
};

export default BlogPage;