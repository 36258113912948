import axios from "axios";

const validateRecaptcha = async (token) => {
  // temporary decision. Need a backend server to validate recaptcha with google. CORS problem
  // develop using drupal backoffice maybe
  return {
    message: "Captcha verification success!!",
    success: true,
  }
  const secretKey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
  // const proxyUrl = process.env.REACT_APP_PROXY_URL;
  const url = `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}`;

  const proxyUrl = 'https://api.allorigins.win/get?url=' + encodeURIComponent(url);

  try {
    const res = await axios.get(proxyUrl);
    if (res.data.success) {
      return {
        message: "Captcha verification success!!",
        success: true,
      }
    };

    return {
      error: "Captcha verification failed!",
      success: false,
    };
  } catch (error) {
    return {
      error: "Captcha verification failed!",
      success: false,
    };
  }
}

export {
  validateRecaptcha
}
