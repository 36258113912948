import * as DrupalApi from '../drupal/apiDrupal'

export async function getItems() {
  const res = await DrupalApi.getItems('project', ['field_images.field_media_image', 'field_tags', 'field_technologies', 'field_roles']);

  if (!res) {
    //throw new Error('Failed to fetch data')
    return null;
  }
  const list = res.data;

  for (let index = 0; index < list.length; index++) {
    // const instId = list[index].relationships.field_institution.data.id;
    // const instRes = await DrupalApi.getItem('institution', instId);
    // if (instRes) {
    //   educationList[index].institution = instRes.data.attributes.title;
    // }

    list[index].name = list[index].attributes.title;
    list[index].description = list[index].attributes.body.value;
    list[index].link = list[index].attributes.field_url[0].uri;
    list[index].linkText = list[index].attributes.field_url[0].title;

    list[index].image = process.env.REACT_APP_DRUPAL_BASE_URL + res.included.filter(item => item.type == "file--file")[0].attributes.uri.url;

    const tagIds = list[index].relationships.field_tags.data.map(tag => tag.id);
    const techIds = list[index].relationships.field_technologies.data.map(tag => tag.id);
    const roleIds = list[index].relationships.field_roles.data.map(tag => tag.id);

    list[index].tags = res.included.filter(item => item.type == "taxonomy_term--tags" && tagIds.includes(item.id)).map(item => item.attributes.name);
    list[index].tech = res.included.filter(item => item.type == "taxonomy_term--technologies" && techIds.includes(item.id)).map(item => item.attributes.name);
    list[index].roles = res.included.filter(item => item.type == "taxonomy_term--roles" && roleIds.includes(item.id)).map(item => item.attributes.name);

  }

  return list;
}

export async function getItem(id) {
  return await DrupalApi.getItem('project', id);
}