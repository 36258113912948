// @flow strict
import { timeConverter } from '../../../utils/time-converter';
import { CImage } from '@coreui/react';
import ExternalLink from '../../ExternalLink';
import { parse } from 'node-html-parser';
import Category from '../../helper/category';

function BlogCard({ blog }) {

  return (
    blog && <div className="border border-[#1d293a] hover:border-[#464c6a] transition-all duration-500 bg-[#1b203e] rounded-lg relative group"
    >
      <div className="h-44 lg:h-52 w-auto cursor-pointer overflow-hidden rounded-t-lg">
        <CImage
          src={blog?.cover_image}
          height={1080}
          width={1920}
          alt=""
          className='h-full w-full group-hover:scale-110 transition-all duration-300'
        />
      </div>
      <div className="p-2 sm:p-3 flex flex-col">
        <div className="flex justify-between items-center text-[#16f2b3] text-sm">
          <p>{timeConverter(blog.pubDate)}</p>
        </div>
        <ExternalLink target='_blank' href={blog.link}>
          <p className='my-2 lg:my-3 cursor-pointer text-lg text-white sm:text-xl font-medium hover:text-violet-500'>
            {blog.title}
          </p>
        </ExternalLink>
        <p className='mb-2 text-sm'>
          {blog?.categories?.map((cat, i) => (
            <Category key={ i } name={cat}/>
          )) }
        </p>
        <p className='text-sm lg:text-base text-[#d3d8e8] pb-3 lg:pb-6 line-clamp-3'>
          {parse(blog.description).outerHTML}
        </p>
        <div className="">
          <ExternalLink target='_blank' href={blog.link}>
            <button className='bg-violet-500 text-white px-3 py-1.5 rounded-full text-xs'>
              Read More
            </button>
          </ExternalLink>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;