import axios from "axios";

export async function getRelated(url, relatedField = 'field_media_image') {

}

export async function getItems(type, includeFields = []) {
  const url = `${process.env.REACT_APP_DRUPAL_BASE_URL}/jsonapi/node/${type}`;

  try {
    const res = await axios.get(url, {
      params: {
        include: includeFields.join(',')
      }
    });

    if (res.status === 200) {
      return res.data;
    };
    return null;
  } catch (error) {
    console.log("ERRROR",error);
    return false;
  }
}

export async function getItem(type, id) {
  const url = `${process.env.REACT_APP_DRUPAL_BASE_URL}/jsonapi/node/${type}/${id}`;

  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    };
    return null;
  } catch (error) {
    console.log("ERRROR",error);
    return false;
  }
}