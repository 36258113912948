import * as DrupalApi from '../drupal/apiDrupal'

export async function getItems() {
  const res = await DrupalApi.getItems('experience');

  if (!res.data) {
    return [];
  }

  for (let index = 0; index < res.data.length; index++) {
    const instId = res.data[index].relationships.field_institution.data.id;
    const instRes = await DrupalApi.getItem('institution', instId);
    if (instRes) {
      res.data[index].institution = instRes.data.attributes.title;
    }
  }
  return res.data;
}