import axios from "axios";

const rss2json_url = process.env.REACT_APP_RSS2JSON_API_URL;
const rss2json_key = process.env.REACT_APP_RSS2JSON_API_KEY;
const medium_url = process.env.REACT_APP_MEDIUM_USER_URL;

export async function getUserInfo() {
  const url = `${process.env.REACT_APP_MEDIUM_API_BASE_URL}/me`;
  const response = await axios.get(url, {
    headers: {
    'Authorization': 'Bearer ' + process.env.REACT_APP_MEDIUM_TOKEN
    }
  });
  return response.data.data ?? null;
}

export async function getUserStories() {
  try {
    const rss = await axios.get(`${rss2json_url}`,
      {
        params: {
          rss_url: medium_url,
          api_key: rss2json_key
        }
      }
    );
    return rss.data.items;
  } catch (error) {
    throw error;
  }
}
