import React from 'react';
import { LineWave } from 'react-loader-spinner'

function SpinnerLoading(){
  return (
    <div style={{with: '100%', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <LineWave
        color="#16f2b3"
        height={100}
        width={100}
        timeout={3000}
      />
    </div>
  )
}

export default SpinnerLoading;
