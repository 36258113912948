import * as DrupalApi from '../drupal/apiDrupal'

export async function getItems() {
  const res = await DrupalApi.getItems('education');

  if (!res) {
    //throw new Error('Failed to fetch data')
    return null;
  }

  const educationList = res.data;

  for (let index = 0; index < educationList.length; index++) {
    const instId = educationList[index].relationships.field_institution.data.id;
    const instRes = await DrupalApi.getItem('institution', instId);
    if (instRes) {
      educationList[index].institution = instRes.data.attributes.title;
    }
  }

  return educationList;
}