export const personalData = {
  name: "Filipe Vilas-Boas",
  profile: '/profile.jpeg',
  designation: "Software Developer & Architect",
  description: "My name is FILIPE VILAS-BOAS. I am a professional and enthusiastic programmer in my daily life. I am a quick learner with a self-learning attitude. I love to learn and explore new technologies and am passionate about problem-solving. I love almost all the stacks of web application development and love to make the web more open to the world. My core skill is based on JavaScript and PHP. I'm also a Drupal specialist. I am available for any kind of job opportunity that suits my skills and interests, and I'm up for a new challenge as well.",
  email: 'filipemvb@gmail.com',
  phone: '+351968966966',
  address: 'Braga, Portugal ',
  github: 'https://github.com/filipevb',
  facebook: 'https://www.facebook.com/filipemvb/',
  linkedIn: 'https://www.linkedin.com/in/filipevb',
  twitter: '',
  stackOverflow: '',
  leetcode: "",
  devUsername: "filipevb",
  resume: "/assets/FilipeVilasBoas_CV.pdf"
}