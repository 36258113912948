import React from "react";

const ExternalLink = ({ target, className, href, children }) => {

  return (
    <a target={target} className={className} href={href}>
      {children}
    </a>
  );
};

export default ExternalLink;