export function getFullMonth(date) {
  return date && date instanceof Date ? date.toLocaleString('default', { month: 'long' }) : null;
};

export function getFullYear(date) {
  return date && date instanceof Date ? date.getFullYear() : null;
}

export function getFullMonthYearDate(date) {
  return date && date instanceof Date ? getFullMonth(date) + ' ' + getFullYear(date) : null
}

export function getCvShowDate(date) {
  if (!date) {
    return null;
  }
  try {
    const auxDate = new Date(date);
    return getFullMonthYearDate(auxDate);
  } catch (error) {
    return null;
  }
}

export function normalizeData(item) {
  item.startDate = getCvShowDate(item.attributes.field_start_date) ?? 'Past';
  item.endDate = getCvShowDate(item.attributes.field_end_date) ?? 'Present';
  return item;
}
