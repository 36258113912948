// @flow strict
import * as MediumAPI from '../api/medium/mediumApi';
import { useEffect, useState } from 'react';
import AboutSection from "../components/homepage/about";
import Blog from "../components/homepage/blog";
import ContactSection from "../components/homepage/contact";
import Education from "../components/homepage/education";
import Experience from "../components/homepage/experience";
import HeroSection from "../components/homepage/hero-section";
import Projects from "../components/homepage/projects";
import Skills from "../components/homepage/skills";

const HomePage = () => {

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Example of fetching data using the ProjectsApi
    const fetchBlogs = async () => {
      const data = await MediumAPI.getUserStories();
      setBlogs(data);
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <HeroSection />
      <AboutSection />
      <Experience />
      <Skills />
      <Projects />
      <Education />
      <Blog blogs={blogs} />
      <ContactSection />
    </>
  );
};

export default HomePage;